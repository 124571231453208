/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/shared/logo.jpg";
import { LangInput, LangContext } from "../LangProvider";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import {
  Ttoggle,
  TmobileMenu,
  TclassGroup,
  TcontentNavbar,
  TdropdownButton,
  TdropdownButtonFn
} from "../types";
import IconMenu from "../assets/shared/menu.svg";

import { urls } from "../params";

import { ListItems } from "../components";

export const NavBarHeader = ({ toggle }: Ttoggle) => (
  <nav className="flex justify-between lg:justify-center items-center bg-white text-black font-mono md:my-4">
    {/* <IconMenu className="p-2" onClick={toggle} /> */}
    <img
      src={IconMenu}
      style={{ height: 36, width: 36 }}
      alt="menu"
      className="md:hidden"
      onClick={toggle}
    />
    <Link to="/" className="md:p-4 justify-self-center mx-auto">
      <div className="flex justify-between item-center">
        <img
          // className="object-left-top max-w-full h-12 align-top border-none"
          className=""
          src={logo}
          width="85"
          height="70"
          alt={LangInput("csn")}
          style={{width: "85px", height: "75px"}}
        />
      </div>
    </Link>
    <div style={{ height: 36, width: 36 }}></div>
  </nav>
);

export const classNames = (...classes: string[]) =>
  classes.filter(Boolean).join(" ");

export const MobileMenu = ({ isOpen, toggle }: TmobileMenu) => (
  <div
    className={isOpen ? "text-center items-center w-full bg-white absolute -mx-4 p-8 z-10 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 min-h-screen" : "hidden"}
    onClick={toggle}>
    <ListItems items={urls} classGroup="list-mobile-nav mb-4" />
    <SwitchLang classGroup="mx-2 bg-transparent hover:text-red-700 focus:border-red-500 focus:outline-none" />
  </div>
);

export const DropdownButton = ({ title, items }: TdropdownButtonFn) => (
  <Menu as="div" className="relative inline-block text-left mr-8">
    <Menu.Button className="inline-flex justify-center w-full py-1 bg-transparent text-gray-700 border-b-4 border-transparent items-center uppercase
      hover:text-red-700 hover:border-red-500 
      focus:border-red-500 focus:outline-none">
      {items.length === 0 && <Link to={`/${title}`}>{LangInput(title)}</Link>}
      {items.length > 0 && (
        <>
          {LangInput(title)}
          <ChevronDownIcon className="-mr-1 ml-1 h-5 w-5" aria-hidden="true" />
        </>
      )}
    </Menu.Button>
    <Transition
      as={React.Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95">
      <Menu.Items className="origin-top-right w-64 absolute left-1 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
        {items.length > 0 && (
          <div className="py-1">
            {items.map((el: TdropdownButton) => (
              <MenuItem key={el.detail} detail={el.detail} url={el.url} />
            ))}
          </div>
        )}
      </Menu.Items>
    </Transition>
  </Menu>
);

export const MenuItem = ({ detail, url }: TdropdownButton) => (
  <Menu.Item>
    {({ active }) => (
      <Link
        to={`${url}`}
        className={classNames(
          active ? "bg-gray-100 text-gray-900 px-8" : "text-gray-700",
          "block px-4 py-2 text-sm whitespace-no-wrap"
        )}>
        {LangInput(detail)}
      </Link>
    )}
  </Menu.Item>
);

export const ContentNavbar = ({ bgColor, children }: TcontentNavbar) => {
  const [isOpenNavbar, setIsOpenNavbar] = React.useState<boolean>(
    window.innerWidth > 768 ? true : false
  );
  React.useEffect(() => {
    const hideMenu = () => {
      if (window.innerWidth > 768) {
        setIsOpenNavbar(true);
        localStorage.setItem("isOpenNavbar", "true");
      } else {
        setIsOpenNavbar(false);
        localStorage.setItem("isOpenNavbar", "false");
      }
    };
    window.addEventListener("resize", hideMenu);
    return () => window.removeEventListener("resize", hideMenu);
  }, [isOpenNavbar]);

  return (
    <nav
      className={`${
        isOpenNavbar
          ? `flex rounded-md items-center justify-start flex-wrap ${bgColor} pb-4`
          : "hidden"
      }`}>
      {children}
    </nav>
  );
};

export const SwitchLang = ({ classGroup }: TclassGroup) => {
  const contextValue = React.useContext(LangContext);
  return (
    <div className="flex justify-center items-center md:justify-center md:items-start text-gray-700 py-1">
      <button
        className={`${classGroup}`}
        onClick={() => contextValue.changeLange("en")}>
        EN
      </button>
      <div className={`${classGroup}`}>|</div>
      <button
        className={`${classGroup}`}
        onClick={() => contextValue.changeLange("tc")}>
        中
      </button>
    </div>
  );
};
