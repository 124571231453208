/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { Link } from "react-router-dom";
import { LangInput } from "../LangProvider";
import { ListItems } from "./Gear";
import { footerWhoWeAre, footerWhatWeDo } from "../params";
import fiveKids from "../assets/fiveKids.png";

export const ReservedBar = () => (
  <div className="bg-gray-100 text-center text-sm text-gray-900 p-6">
    {LangInput("copyright_year")} <Link to="/home">{LangInput("csn")}</Link>
    {`.  `} {LangInput("all_right")}
  </div>
);

export const Footer = () => {
  return (
    <div className="flex md:flex-row flex-col bg-purple-600 p-16">
      <div className="m-2">
        <span className="text-2xl text-white bold">{LangInput("home")}</span>
        <ListItems
          classGroup="list-none my-4"
          detail="text-sm text-white"
          items={[
            {
              title: "home",
              url: "/home"
            }
          ]}
        />
      </div>
      <div className="m-2">
        <span className="text-2xl text-white bold">
          {LangInput("WhoWeAre")}
        </span>
        <ListItems
          classGroup="list-none my-4"
          detail="text-sm text-white"
          items={footerWhoWeAre}
        />
      </div>
      <div className="m-2">
        <span className="text-2xl text-white bold">
          {LangInput("WhatWeDo")}
        </span>
        <ListItems
          classGroup="list-none my-4"
          detail="text-sm text-white"
          items={footerWhatWeDo}
        />
      </div>
      <div className="m-2">
        <span className="text-2xl text-white bold">
          {LangInput("bestOfBreed")}
        </span>
        <ListItems
          classGroup="list-none my-4"
          detail="text-sm text-white"
          items={[
            {
              title: "bestOfBreed",
              url: "/bestOfBreed"
            }
          ]}
        />
      </div>
      <div className="m-2">
        <img src={fiveKids} alt="fiveKids" className="w-140" />
      </div>
    </div>
  );
};
