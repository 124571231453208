/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { LangInput } from "../LangProvider";
import building from "../assets/building.jpg";
import school from "../assets/school.png";

export const Vision = () => {
  return (
    <>
      <div className="flex lg:flex-row flex-col">
        <div className="flex flex-col m-4">
          <p
            className={`text-xl text-white bg-red-500 px-4 py-2 rounded-full ${
              LangInput("menu") === "Menu" ? "w-28" : "w-33.2"
            }`}>
            {LangInput("ourVision")}
          </p>
          <p className="my-4">{LangInput("ourVisionContent")}</p>
          <img src={school} alt="school" className="w-33" />
        </div>
        <img src={building} alt="building" className="w-108" />
      </div>
    </>
  );
};
