/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { LangInput } from "../LangProvider";
import {
  improveOperational,
  improveUser,
  utilizeNew,
  improveTeaching,
  understandBigPicture
} from "../params";
import { ReactComponent as Icon1 } from "../assets/digitalization/kv-1.svg";
import { ReactComponent as Icon2 } from "../assets/digitalization/kv-2.svg";
import { ReactComponent as Icon3 } from "../assets/digitalization/kv-3.svg";
import { ReactComponent as Icon4 } from "../assets/digitalization/kv-4.svg";
import { ReactComponent as Icon5 } from "../assets/digitalization/kv-5.svg";

export const DigitalAdvantageCard = ({
  icon,
  title,
  list
}: {
  icon?: JSX.Element;
  title?: string;
  list?: string[];
}) => (
  <article
    className={`p-8 rounded-lg m-2 flex-1 ${
      icon && title && list ? "bg-primary-lightBg" : ""
    }`}>
    <h2 className="text-sub-title mb-4 text-gray-800">
      {title ? LangInput(title) : ""}
    </h2>
    <div className="md:flex justify-between flex-wrap xl:flex-no-wrap">
      <ul className="list-standard md:pr-8 mb-8 md:mb-0">
        {list && list.map(el => <li key={el}>{LangInput(el)}</li>)}
      </ul>
      <div className="w-56 flex justify-center md:justify-end">{icon}</div>
    </div>
  </article>
);

export const Digitalization = () => {
  return (
    <section className="container mx-auto">
      <h1 className="text-page-title my-16">
        {LangInput("whyDigitalization")}
      </h1>
      <div className="mb-24">
        <div className="flex justify-between flex-wrap">
          <DigitalAdvantageCard
            icon={<Icon1 />}
            title="ImproveOperational"
            list={improveOperational.map(
              el => el.title
            )}></DigitalAdvantageCard>
          <DigitalAdvantageCard
            icon={<Icon2 />}
            title="ImproveUser"
            list={improveUser.map(el => el.title)}></DigitalAdvantageCard>
        </div>
        <div className="flex justify-between flex-wrap">
          <DigitalAdvantageCard
            icon={<Icon3 />}
            title="UtilizeNew"
            list={utilizeNew.map(el => el.title)}></DigitalAdvantageCard>
          <DigitalAdvantageCard
            icon={<Icon4 />}
            title="ImproveTeaching"
            list={improveTeaching.map(el => el.title)}></DigitalAdvantageCard>
        </div>
        <div className="flex justify-between flex-wrap">
          <DigitalAdvantageCard
            icon={<Icon5 />}
            title="UnderstandBigPicture"
            list={understandBigPicture.map(
              el => el.title
            )}></DigitalAdvantageCard>
          <DigitalAdvantageCard></DigitalAdvantageCard>
        </div>
      </div>
    </section>
  );
};
