/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { LangInput } from "../LangProvider";
import { NextPrevNav } from "../components/Gear";
import { WhatWeDo, successDigitalLearning } from "../params";
import { ReactComponent as IconIdea } from "../assets/what-we-do/icon-idea.svg";
import { ReactComponent as Kv } from "../assets/what-we-do/kv-digital-learning.svg";
import { ReactComponent as Bg } from "../assets/what-we-do/bg-3.svg";
// import { ReactComponent as Icon } from "../assets/what-we-do/icon-digital-learning.svg";

export const DigitalLearning = () => {
  return (
    <section className="relative">
      {/* Summary */}
      <section className="container mx-auto ">
        <h1 className="text-page-title text-tertiary normal-case ">
          {LangInput("digitalLearning")}
        </h1>
        <br />
        <p>{LangInput("digitalLearningALO1")}</p>
        <br />
        <p>{LangInput("digitalLearningALO2")}</p>
        <br />
        <p>{LangInput("digitalLearningALO3")}</p>
        <br />
        <p>{LangInput("digitalLearningALO4")}</p>
        <br />
        <p>
          {LangInput("detailPleaseReferTo")}

          <a
            className="underline hover:no-underline
                   text-blue-600 hover:text-blue-800 
                   visited:text-purple-600"
            href="https://alo-global.com/"
            rel="alo-global.com">
            {" "}
            alo-global.com
          </a>
        </p>

        {/* <ul className="list-standard md:ml-16">
          {digitalLearning &&
            digitalLearning.map(el => (
              <li key={el.title}>{LangInput(el.title)}</li>
            ))}
        </ul> */}
      </section>

      {/* BG visuals */}
      <section className="relative overflow-hidden">
        <Bg className="invisible mt-24" />
        <Bg className="absolute bottom-0 left-0 right-0 mx-auto" />
        <Kv
          className="absolute top-0 left-0 right-0 mx-auto"
          style={{ width: "80%" }}
        />
      </section>

      <section className="bg-blueBg">
        <div className="container mx-auto md:p-8">
          <h2 className="text-sub-title text-tertiary flex items-center mb-8">
            <IconIdea className="w-12 mr-4" />
            {LangInput("sucessDigitalLearning")}
          </h2>
          <section className="md:flex justify-between pb-16 md:pb-0 md:my-8">
            <ul className="list-standard text-base md:text-justify flex-1 md:mr-16">
              {successDigitalLearning &&
                successDigitalLearning
                  .filter((e, idx) => idx < successDigitalLearning.length / 2)
                  .map(el => <li key={el.title}>{LangInput(el.title)}</li>)}
            </ul>
            <ul className="list-standard text-base md:text-justify flex-1">
              {successDigitalLearning &&
                successDigitalLearning
                  .filter((e, idx) => idx >= successDigitalLearning.length / 2)
                  .map(el => <li key={el.title}>{LangInput(el.title)}</li>)}
            </ul>
          </section>
        </div>
      </section>

      {/* Next / Prev */}
      <NextPrevNav
        next={WhatWeDo.items[3].url}
        prev={WhatWeDo.items[1].url}></NextPrevNav>
    </section>
  );
};
