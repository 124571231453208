import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./tailwind.css";

import { HomeItem, WhoWeAre, WhatWeDo, bestOfBreedT } from "./params";
import {
  MobileMenu,
  ReservedBar,
  NavBarHeader,
  ContentNavbar,
  DropdownButton,
  SwitchLang
} from "./components";
import { LangContextProvider } from "./LangProvider";
import {
  Home,
  CSNnet,
  Vision,
  Mission,
  Axispad,
  BigData,
  AboutCSN,
  ContactUs,
  BestOfBreed,
  ESGPrinciple,
  Digitalization,
  SupportingLife,
  DigitalLearning
} from "./pages";

const App = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const toggle = () => setIsOpen(prevState => !prevState);

  React.useEffect(() => {
    const hideMenu = () => {
      if (window.innerWidth > 768 && isOpen) {
        setIsOpen(false);
        console.log("isOpen", isOpen);
      }
    };
    window.addEventListener("resize", hideMenu);

    return () => window.removeEventListener("resize", hideMenu);
  }, [isOpen]);

  return (
    <Router>
      <LangContextProvider>
        <div>
          <div className="lg:container lg:mx-auto p-4 lg:p-0 relative">
            <NavBarHeader toggle={toggle} />
            <MobileMenu isOpen={isOpen} toggle={toggle} />
            <div className="hidden md:flex justify-between mb-4">
              <ContentNavbar bgColor="bg-white">
                <DropdownButton {...HomeItem} />
                <DropdownButton {...WhoWeAre} />
                <DropdownButton {...WhatWeDo} />
                <DropdownButton {...bestOfBreedT} />
              </ContentNavbar>
              <SwitchLang classGroup="mx-2 bg-transparent hover:text-red-700 focus:border-red-500 focus:outline-none hidden md:block" />
            </div>
          </div>
          <Switch>
            <Route path="/home" component={Home} />
            <Route path="/whoWeAre" component={AboutCSN} />
            <Route path="/vision" component={Vision} />
            <Route path="/mission" component={Mission} />
            <Route path="/esg" component={ESGPrinciple} />
            <Route path="/contact" component={ContactUs} />
            <Route path="/csnnet" component={CSNnet} />
            <Route path="/axispad" component={Axispad} />
            <Route path="/bigData" component={BigData} />
            <Route path="/parentsEducation" component={DigitalLearning} />
            <Route path="/digitalization" component={Digitalization} />
            <Route path="/supportingLife" component={SupportingLife} />
            <Route path="/bestOfBreed" component={BestOfBreed} />
            <Route path="/" component={Home} />
          </Switch>
        </div>
        <ReservedBar />
      </LangContextProvider>
    </Router>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root") as HTMLElement
);
