/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
// import ReactPlayer from "react-player";
import "swiper/swiper-bundle.min.css";
import "swiper/modules/autoplay/autoplay.min.css";
import kv1 from "../assets/home/kv-landing.jpg";
// import kv2 from "../assets/home/kv-landing-2.jpg";
import videoEn from "../assets/home/banner-en.mp4";
import videoCn from "../assets/home/banner-cn.mp4";
import { MoreLink } from "../components";
import { LangContext, LangInput } from "../LangProvider";
import { ContactUsSection } from "../components/ContactUsSection";
import { WhatWeDo, WhoWeAre } from "../params";
import kvSocialCare from "../assets/shared/kv-social-care.png";
// import { ReactComponent as IconBigData }from "../assets/home/icon-big-data.svg";
// import { ReactComponent as IconCsnNet } from "../assets/home/icon-csn-net.svg";
import IconCsnNet from "../assets/home/icon-csn-net.png";
import IconPad from "../assets/home/icon-pad.png";
import IconLearning from "../assets/home/icon-learning.png";
import IconBigData from "../assets/home/icon-big-data.png";
import ReactPlayer from "react-player";
// import { ReactComponent as IconLearning } from "../assets/home/icon-learning.svg";
// import { ReactComponent as IconPad } from "../assets/home/icon-pad.svg";

export const WhatWeDoItem = ({
  icon,
  url,
  name
}: {
  icon: string;
  url: string;
  name: string;
}) => (
  <div
    className="py-8 px-4 flex flex-col items-center transition duration-150 ease-in transform hover:-translate-y-2 hover:scale-110"
    onClick={() => {
      window.location.href = url;
    }}>
    <div
      className="mb-6 rounded-full"
      style={{
        background: "rgba(0, 0, 0, .05)",
        width: "200px",
        height: "200px",
        padding: "2.5rem"
      }}>
      <img src={icon} alt="icon" />
    </div>
    <h4 className="text-2xl text-gray-900 font-semibold mb-2">{name}</h4>
    <div className="text-tertiary">
      <MoreLink url={url}></MoreLink>
    </div>
  </div>
);

export const Home = () => {
  const contextValue = React.useContext(LangContext);

  return (
    <>
      <Swiper
        modules={[Autoplay, EffectFade]}
        autoplay={{ delay: 13000 }}
        effect="fade"
        spaceBetween={0}
        slidesPerView={1}
        pagination={{ clickable: true }}>
        <SwiperSlide>
          {/* <img
            className="w-full"
            src={kv2}
            alt="cover"
          /> */}
          {/* <video src={contextValue.langChange === "en" ? videoEn : videoCn}
                 preload="auto" 
                 autoPlay
                 muted={true}
                 style={{width: '100%', height: '100%'}}></video> */}
          <ReactPlayer
            url={contextValue.langChange === "en" ? videoEn : videoCn}
            width="100%"
            height="100%"
            volume={0}
            muted
            playsinline={true}
            playing={true}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img className="w-full" src={kv1} alt="cover" />
        </SwiperSlide>
      </Swiper>

      {/* About CSN */}
      <section className="container mx-auto py-8 md:flex md:flex-row justify-between items-center">
        <div className="hidden md:block md:w-3/6 mb-8">
          <img src={kvSocialCare} alt={LangInput("aboutcsn")} />
        </div>
        <div className="md:w-3/6 md:ml-8">
          <div className="text-title mb-8">{LangInput("aboutcsn")}</div>
          <div className="text-content mb-2">
            <p className="mb-4">{LangInput("aboutcsnContent")}</p>
            <div className="text-primary">
              <MoreLink url={WhoWeAre.url}></MoreLink>
            </div>
          </div>
        </div>
        <div className="md:hidden mb-8">
          <img src={kvSocialCare} alt={LangInput("aboutcsn")} />
        </div>
      </section>

      {/* What we do */}
      <section className="bg-blueBg">
        <div className="container mx-auto py-16">
          <div className="flex justify-between items-start">
            <div className="text-title text-gray-900 mb-8">
              {LangInput("WhatWeDo")}
              <div className="w-20 border-tertiary border-b-8 mt-2"></div>
            </div>
            {/* <div className="my-4">
              <MoreLink url={WhatWeDo.items[0].url}></MoreLink>
            </div> */}
          </div>
          <div className="md:flex md:flex justify-between px-8">
            <WhatWeDoItem
              icon={IconCsnNet}
              name={LangInput("csnNet")}
              url={WhatWeDo.items[0].url}></WhatWeDoItem>
            <WhatWeDoItem
              icon={IconPad}
              name={LangInput("axispad")}
              url={WhatWeDo.items[1].url}></WhatWeDoItem>
            <WhatWeDoItem
              icon={IconLearning}
              name={LangInput("digitalLearning")}
              url={WhatWeDo.items[2].url}></WhatWeDoItem>
            <WhatWeDoItem
              icon={IconBigData}
              name={LangInput("bigData")}
              url={WhatWeDo.items[3].url}></WhatWeDoItem>
            {/* <WhatWeDoItem icon={<IconCsnNet width="100%" />} name={LangInput("csnNet")} url={WhatWeDo.items[0].url}></WhatWeDoItem>
            <WhatWeDoItem icon={<IconPad width="100%" />} name={LangInput("axispad")} url={WhatWeDo.items[1].url}></WhatWeDoItem>
            <WhatWeDoItem icon={<IconLearning width="100%" />} name={LangInput("digitalLearning")} url={WhatWeDo.items[2].url}></WhatWeDoItem>
            <WhatWeDoItem icon={<IconBigData width="100%" />} name={LangInput("bigData")} url={WhatWeDo.items[3].url}></WhatWeDoItem> */}
          </div>
        </div>
      </section>

      {/* Contact us */}
      <ContactUsSection></ContactUsSection>
    </>
  );
};
