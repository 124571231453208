/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { LangInput } from "../LangProvider";
import { ReactComponent as Kv } from "../assets/what-we-do/kv-pad.svg";
import { ReactComponent as Bg } from "../assets/what-we-do/bg-2.svg";
import { ReactComponent as Icon } from "../assets/what-we-do/icon-pad.svg";
import { NextPrevNav } from "../components/Gear";
import { axispad, WhatWeDo } from "../params";

export const Axispad = () => {
  return (
    <section className="relative">

      {/* Summary */}
      <section className="container mx-auto md:flex justify-center items-center md:my-16">
        <div className="my-8 md:my-0">
          <Icon className="mx-auto md:m-0 mb-4" />
          <h1 className="text-page-title text-tertiary normal-case">{LangInput("axispad")}</h1>
        </div>
        <ul className="list-standard md:ml-16">
          {axispad && axispad.map(el => (
            <li key={el.title}>{LangInput(el.title)}</li>
          ))}
        </ul>
      </section>

      {/* BG visuals */}
      <section className="relative overflow-hidden mt-16 md:mt-0">
        <Bg className="invisible mt-24" />
        <Bg className="absolute bottom-0 left-0 right-0 mx-auto" />
        <Kv className="absolute top-0 left-0 right-0 mx-auto"  style={{ width: '70%' }} />
      </section>

      {/* Next / Prev */}
      <NextPrevNav next={WhatWeDo.items[2].url} prev={WhatWeDo.items[0].url}></NextPrevNav>      
    </section>
  );
};
