/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { LangInput } from "../LangProvider";
import mainCover from "../assets/home/kv-landing.png";
import { ReactComponent as IconArrow } from "../assets/shared/arrow-forward.svg";
import { ReactComponent as Next } from "../assets/shared/arrow-next.svg";
import { ReactComponent as Prev } from "../assets/shared/arrow-prev.svg";
import { Link } from "react-router-dom";
import {
  Titems,
  TurlLisk,
  TurlLiskFn,
  TcolorButon,
  TsupportTable,
  TbgColorButton,
  TDigiAdvantageBall,
  TcontentNavbarButton
} from "../types";

import {
  improveOperational,
  improveUser,
  utilizeNew,
  improveTeaching,
  understandBigPicture
} from "../params";

export const ColorButton = ({ number, detail, color }: TcolorButon) => (
  <div className={`border-r-24 border-${color}-500 rounded-full rounded-r-2xl`}>
    <div className={`border-4 border-${color}-300 rounded-full p-2 m-2`}>
      <div className="flex">
        <div
          className={`border-2 rounded-full bg-${color}-500 text-white px-${
            number === 1 ? 2.5 : 2
          } mx-1 h-7.5 `}>
          {number}
        </div>
        <div className={`text-${color}-500`}>{LangInput(detail)}</div>
      </div>
    </div>
  </div>
);

export const BgColorButton = ({
  title,
  bgColor,
  color,
  textSize
}: TbgColorButton) => (
  <div
    className={`border-8 rounded-full ${bgColor} ${color} ${textSize} px-4 py-2`}>
    {LangInput(title)}
  </div>
);

export const Cover = () => (
  <img
    className="w-full"
    src={mainCover}
    alt="cover"
  />
);

export const ButtonList = ({ items }: Titems) => (
  <>
    {items &&
      items.map(el => (
        <BgColorButton
          key={el}
          title={el}
          bgColor="bg-white"
          color="text-purple-500"
          textSize="text-xl"
        />
      ))}
  </>
);

export const SupportTable = ({ title, bgColor, detail }: TsupportTable) => (
  <div className={`${bgColor} p-4 m-2`}>
    <p className="text-black text-center">{LangInput(title)}</p>
    <p className="rounded-full bg-white px-4 py-2 m-4">{LangInput(detail)}</p>
    <p className="rounded-full bg-white px-4 py-2">
      <p className="text-red-500">
        {LangInput("csnPlatform")} {`:`}
      </p>
      {LangInput("ParentsSchoolsCaregivers")}
    </p>
  </div>
);

export const FormativeStageButton = ({
  title,
  color,
  url
}: TcontentNavbarButton) => (
  <div className="flex flex-col w-24 mb-2 md:mx-6 mx-auto item-center justify-center">
    <img src={url} alt={title} className="w-24 h-36" />
    <p className={`rounded-lg text-md text-center text-white ${color}`}>
      {LangInput(title)}
    </p>
  </div>
);

export const ListItems = ({ classGroup, detail, items }: TurlLiskFn) => (
  <ul className={classGroup ? `${classGroup}` : ""}>
    {items &&
      items.map((el: TurlLisk) =>
        !el.url ? (
          <li className={detail ? `${detail}` : ""} key={el.title}>
            {LangInput(el.title)}
          </li>
        ) : (
          <li className={detail ? `${detail}` : ""} key={el.title}>
            <Link to={el.url}>{LangInput(el.title)} </Link>
          </li>
        )
      )}
  </ul>
);

export const DigiAdvantageBall = ({
  title,
  classGroupA,
  classGroupB,
  items
}: TDigiAdvantageBall) => {
  return (
    <div className={classGroupA}>
      <p className={classGroupB}>{LangInput(title)}</p>
      <ListItems
        classGroup="list-none my-4"
        detail="text-sm text-center"
        items={items}
      />
    </div>
  );
};

export const MoreLink = ({url}: {url: string}) => {
  return <Link to={url} className="flex items-center fill-current">
    {LangInput("learnMore")}
    <IconArrow className="ml-2" />
  </Link>
}

export const NextPrevNav = ({next, prev}: {next: string, prev: string}) => {
  return <nav>
    <div className="absolute md:fixed left-0 top-2 md:top-1/2 m-4 md:m-8 md:transform md:-translate-y-1/2">
        <Link to={prev}><Prev /></Link>
      </div>
      <div className="absolute md:fixed right-0 top-2 md:top-1/2 m-4 md:m-8 md:transform md:-translate-y-1/2">
        <Link to={next}><Next /></Link>
      </div>
  </nav>
}