/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { LangInput } from "../LangProvider";
import kids from "../assets/kids.png";
import dad from "../assets/dad.jpg";

export const Mission = () => {
  return (
    <div className="flex md:flex-row flex-col">
      <div className="flex flex-col">
        <p
          className={`text-xl text-white bg-red-500 px-4 py-2 rounded-full ${
            LangInput("menu") === "Menu" ? "w-33.5" : "w-33.2"
          }`}>
          {LangInput("ourMission")}
        </p>
        <p className="my-4">{LangInput("ourMissionContent")}</p>
        <img src={kids} alt="kids" className="w-108" />
      </div>
      <span className="flex md:flex-row flex-col justify-center m-4">
        <img src={dad} alt="dad" className="w-220" />
      </span>
    </div>
  );
};
