/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { LangInput } from "../LangProvider";
import contact from "../assets/contact.jpg";
import adult from "../assets/adult.png";

export const ContactUs = () => {
  return (
    <div className="m-4">
      <div className="flex md:flex-row flex-col justify-between">
        <span>
          <p
            className={`text-xl text-white bg-red-500 px-4 py-2 rounded-full ${
              LangInput("menu") === "Menu" ? "w-30" : "w-25"
            }`}>
            {LangInput("contact")}
          </p>
          <p className="text-gray-500 my-4">{LangInput("ngo")}</p>
          <p className="my-4">{LangInput("hotline")}</p>
          <p className="my-4">{LangInput("whatsapp")}</p>
          <p className="my-4">{LangInput("email")}</p>
          <p className="my-4">{LangInput("address")}</p>
        </span>
        <img src={adult} alt="adult" className="w-108" />
      </div>
      <img src={contact} alt="contact" className="w-108" />
    </div>
  );
};
