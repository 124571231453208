/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { LangInput } from "../LangProvider";
import { bigData, bigDataBenefits, supportingData, WhatWeDo } from "../params";
import { ReactComponent as Kv } from "../assets/what-we-do/kv-big-data.svg";
import { ReactComponent as Bg } from "../assets/what-we-do/bg-4.svg";
import { ReactComponent as Icon } from "../assets/what-we-do/icon-big-data.svg";
import IconSecurity from "../assets/what-we-do/icon-security.png";
import { NextPrevNav } from "../components/Gear";

export const DataPrivacyItem = ({index, title}: {index: number, title: string}) => (
  <div className="flex items-center mb-4">
    <span className="bullet mr-4">{index}</span>
    <p>{LangInput(title)}</p>
  </div>
);

export const BigData = () => {
  return (
    <section className="relative">

      {/* Summary */}
      <section className="container mx-auto md:flex justify-center items-center md:my-16">
        <div className="md:flex flex-col items-center my-8 md:my-0">
          <Icon className="mx-auto md:m-0 mb-4" />
          <h1 className="text-page-title text-tertiary normal-case">{LangInput("bigData")}</h1>
        </div>
        <ul className="list-standard md:ml-16 md:w-1/2">
          {bigData && bigData.map(el => (
            <li key={el.title}>{LangInput(el.title)}</li>
          ))}
        </ul>
      </section>

      {/* BG visuals */}
      <section className="relative overflow-hidden">
        <Bg className="invisible mt-8 md:mt-24" />
        <Bg className="absolute bottom-0 left-0 right-0 mx-auto" />
        <Kv className="absolute top-0 left-0 right-0 mx-auto" style={{ width: '80%' }} />
      </section>

      <section className="bg-blueBg">
        <div className="container mx-auto p-8 md:flex justify-between pb-8 md:pb-16">

          {/* big data benefits */}
          <article className="flex-1 md:mr-16 mb-16 md:mb-0">
            <h2 className="text-sub-title text-tertiary mb-4">
              {LangInput("BigDataBenefits")}
            </h2>
            <ul className="list-standard text-base">
              {bigDataBenefits && bigDataBenefits.map(el => (
                <li key={el.title}>{LangInput(el.title)}</li>
              ))}
            </ul>
          </article>

          {/* supporting data */}
          <article className="flex-1">
            <h2 className="text-sub-title text-tertiary mb-4">
              {LangInput("SupportingData")}
            </h2>
            <ul className="list-standard text-base">
              {supportingData && supportingData.map(el => (
                <li key={el.title}>{LangInput(el.title)}</li>
              ))}
            </ul>
          </article>          
        </div>

        {/* data privacy & collection */}
        <div className="container mx-auto p-8 md:flex justify-between items-center pb-16">
          <article className="md:w-3/4">
          <img src={IconSecurity} alt={LangInput("dataPrivayCollectionMining")} className="p-8 md:hidden mx-auto" />
            <h2 className="text-sub-title text-tertiary mb-4">
              {LangInput("dataPrivayCollectionMining")}
            </h2>
            <p className="text-content text-base mb-8">
              {LangInput("WeCommit")}
            </p>
            <div className="md:flex">
              <div className="md:w-1/2">
                <DataPrivacyItem index={1} title="LawfulFair"></DataPrivacyItem>
                <DataPrivacyItem index={2} title="CollectedFor"></DataPrivacyItem>
                <DataPrivacyItem index={3} title="Security"></DataPrivacyItem>
              </div>
              <div className="md:w-1/2">
                <DataPrivacyItem index={4} title="Accuracy"></DataPrivacyItem>
                <DataPrivacyItem index={5} title="StorageLimitation"></DataPrivacyItem>
                <DataPrivacyItem index={6} title="PurposeLimitation"></DataPrivacyItem>
              </div>
            </div>
          </article>
          <img src={IconSecurity} alt={LangInput("dataPrivayCollectionMining")} className="p-8 pt-16 hidden md:block mx-auto"  />
        </div>
      </section>

      {/* Next / Prev */}
      <NextPrevNav next={WhatWeDo.items[0].url} prev={WhatWeDo.items[2].url}></NextPrevNav>      
    </section>

  );
};
