export const HomeItem = {
  title: "home",
  items: []
};

export const WhatWeDo = {
  title: "WhatWeDo",
  items: [
    {
      detail: "digitalLearning",
      url: "/parentsEducation"
    },
    {
      detail: "csnNet",
      url: "/csnNet"
    },
    {
      detail: "axispad",
      url: "/axispad"
    },
    {
      detail: "bigData",
      url: "/bigData"
    },
    {
      detail: "digitalization",
      url: "/digitalization"
    },
    {
      detail: "supportingLife",
      url: "/supportingLife"
    }
  ]
};

export const WhoWeAre = {
  title: "WhoWeAre",
  url: "/whoWeAre",
  items: []
  // items: [
  //   {
  //     detail: "aboutcsn",
  //     url: "/aboutcsn"
  //   },
  //   {
  //     detail: "vision",
  //     url: "/vision"
  //   },
  //   {
  //     detail: "mission",
  //     url: "/mission"
  //   },
  //   {
  //     detail: "esg",
  //     url: "/esg"
  //   },
  //   {
  //     detail: "contact",
  //     url: "/contact"
  //   }
  // ]
};

export const bestOfBreedT = {
  title: "bestOfBreed",
  items: []
};

export const urls = [
  {
    title: "home",
    url: "/home"
  },
  {
    title: "WhoWeAre",
    url: "/whoWeAre"
  },
  {
    title: "csnNet",
    url: "/csnNet"
  },
  {
    title: "axispad",
    url: "/axispad"
  },
  {
    title: "digitalLearning",
    url: "/parentsEducation"
  },
  {
    title: "bigData",
    url: "/bigData"
  },
  {
    title: "digitalization",
    url: "/digitalization"
  },
  {
    title: "supportingLife",
    url: "/supportingLife"
  },
  {
    title: "bestOfBreed",
    url: "/bestOfBreed"
  }
];

export const knowledgeableParents = [
  { title: "FullCircle" },
  { title: "WeTrain" },
  { title: "KidSpend" },
  { title: "ButTheir" },
  { title: "accessInto" },
  { title: "LearnGood" },
  { title: "FullIntegration" },
  { title: "InvolvementWith" },
  { title: "AlleviateThe" },
  { title: "StrategiesFor" }
];

export const bestOfBreed = [
  { title: "CSNis" },
  { title: "WorldLeading" },
  { title: "MultiplePatents" },
  { title: "MultiplePrestigious" },
  { title: "RecognisedAnd" }
];

export const footerWhoWeAre = [
  {
    title: "aboutcsn",
    url: "/aboutcsn"
  },
  {
    title: "vision",
    url: "/vision"
  },
  {
    title: "mission",
    url: "/mission"
  },
  {
    title: "esg",
    url: "/esg"
  },
  {
    title: "contact",
    url: "/contact"
  }
];

export const footerWhatWeDo = [
  {
    title: "csnNet",
    url: "/csnNet"
  },
  {
    title: "axispad",
    url: "/axispad"
  },
  {
    title: "digitalLearning",
    url: "/parentsEducation"
  },
  {
    title: "bigData",
    url: "/bigData"
  },
  {
    title: "digitalization",
    url: "/digitalization"
  },
  {
    title: "supportingLife",
    url: "/supportingLife"
  }
];

export const csnnet = [
  { title: "Asecured" },
  { title: "CutDown" },
  { title: "Freeup" },
  { title: "RealTime" }
];

export const axispad = [
  { title: "Ahandy" },
  { title: "SupportRemote" },
  { title: "MultiMedia" },
  { title: "RealTimeSharing" }
];

export const digitalLearning = [
  { title: "AggregatedContent" },
  { title: "SupportPositive" },
  { title: "ProvideParents" },
  { title: "AccessVia" }
];

export const successDigitalLearning = [
  { title: "MeaningfulWellThought" },
  { title: "StakeholderLed" },
  { title: "StudentsNeed" },
  { title: "RightComputerSkills" },
  { title: "BestUseOfTime" },
  { title: "MindfulOfShortAttention" },
  { title: "ProvidingLearnAsYou" },
  { title: "RealTimeOnline" },
  { title: "DedicatedDigital" }
];

export const bigData = [
  { title: "BuildindInfo" },
  { title: "SupportResearch" },
  { title: "SupportFunding" },
  { title: "MeasureWork" },
  { title: "GenerateRegular" }
];

export const bigDataBenefits = [
  { title: "SecureDataCollection" },
  { title: "ConfidentialData" },
  { title: "SecureStorage" },
  { title: "LargeVolume" },
  { title: "SupportIndustry" }
];

export const supportingData = [
  { title: "StudentsAffected" },
  { title: "GdpLose" },
  { title: "Covid19Impact" },
  { title: "LowIncomeFamily" }
];

export const improveOperational = [
  { title: "OutdatedLegacy" },
  { title: "ManualProcesses" },
  { title: "PoorCommunications" },
  { title: "RemoteWorkforce" }
];

export const improveUser = [
  { title: "ReduceStaff" },
  { title: "FreeUpFace" },
  { title: "ReduceAdministration" }
];

export const utilizeNew = [
  { title: "ImproveAgility" },
  { title: "RespondChange" },
  { title: "CopeUnexpected" }
];

export const improveTeaching = [
  { title: "ConnectWith" },
  { title: "TimelinessDelivery" },
  { title: "InclusivityIntegration" },
  { title: "SustainabilityAccountability" }
];

export const understandBigPicture = [
  { title: "InformationKnowledge" },
  { title: "SupportForResearch" },
  { title: "SupportForGovernment" }
];
