/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { LangInput } from "../LangProvider";
import kvSocialCare from "../assets/who-we-are/kv-social-care.png";
import kvEsg from "../assets/who-we-are/kv-esg.png";
import divider from "../assets/who-we-are/divider.svg";
import { ContactUsSection } from "../components/ContactUsSection";

export const AboutCSN = () => {
  return (
    <div>
      <section className="container mx-auto mb-32">
        <h1 className="text-title my-16">{LangInput("aboutcsn")}</h1>
        <section className="mb-16">
          <p className="md:w-3/6 text-content text-lg">
            {LangInput("aboutcsnContent")}
          </p>
        </section>
        <section className="md:flex items-end">
          <div className="md:w-1/3">
            <h1 className="text-sub-title text-2xl mb-8">
              {LangInput("ourVision")}
            </h1>
            <p className="text-content mb-16">
              {LangInput("ourVisionContent")}
            </p>
            <h1 className="text-sub-title text-2xl mb-8">
              {LangInput("ourMission")}
            </h1>
            <p className="text-content">{LangInput("ourMissionContent")}</p>
          </div>
          <div className="md:w-2/3 my-16 md:my-0 flex justify-end">
            <img src={kvSocialCare} alt="kvSocialCare" />
          </div>
        </section>
      </section>

      {/* ESG */}
      <section className="container mx-auto mb-16 border-solid border-secondary-lightBg border-16 p-4 md:p-16 text-center">
        <img src={kvEsg} className="mx-auto mb-4" alt="kvEsg" />
        <h1 className="text-title text-gray-800 mb-4">{LangInput("esg")}</h1>
        <p className="text-content mx-auto mb-8 md:w-1/2 sm:w-auto md:w-full lg:w-1/2">
          {LangInput("NotJust")}
        </p>
        <div>
          <img
            src={divider}
            className="hidden md:block mx-auto mb-4 w-4/6"
            alt="divider"
          />
          <div className="md:flex justify-around">
            <article className="mb-4 md:mb-0 md:w-1/3 bg-secondary-lightBg p-8 mx-2 text-left">
              <h2 className="text-sub-title text-emphasize-first text-gray-800 mb-4">
                {LangInput("Environmental")}
              </h2>
              <ul className="list-standard">
                <li>{LangInput("SavePaper")}</li>
                <li>{LangInput("ReduceTravel")}</li>
                <li>{LangInput("CutsPollution")}</li>
              </ul>
            </article>
            <article className="mb-4 md:mb-0 md:w-1/3 bg-secondary-lightBg p-8 mx-2 text-left">
              <h2 className="text-sub-title text-emphasize-first text-gray-800 mb-4">
                {LangInput("Social")}
              </h2>
              <ul className="list-standard">
                <li>{LangInput("PhilanthropicProjects")}</li>
                <li>{LangInput("CorporateSocial")}</li>
                <li>{LangInput("ImproveCommunication")}</li>
                <li>{LangInput("InclusionDiversity")}</li>
                <li>{LangInput("SupportSocialWelfare")}</li>
              </ul>
            </article>
            <article className="mb-4 md:mb-0 md:w-1/3 bg-secondary-lightBg p-8 mx-2 text-left">
              <h2 className="text-sub-title text-emphasize-first text-gray-800 mb-4">
                {LangInput("Governance")}
              </h2>
              <ul className="list-standard">
                <li>{LangInput("EnhanceCooperation")}</li>
                <li>{LangInput("ImproveRecordKeeping")}</li>
                <li>{LangInput("MonitorCompliance")}</li>
                <li>{LangInput("ReduceStaffTurnover")}</li>
                <li>{LangInput("ImproveStaff")}</li>
              </ul>
            </article>
          </div>
        </div>
      </section>

      {/* Contact us */}
      <ContactUsSection></ContactUsSection>
    </div>
  );
};
