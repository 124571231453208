/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { LangInput } from "../LangProvider";
import trophy from "../assets/best-of-breed/trophy.png";
import kvTrophy from "../assets/best-of-breed/kv-trophy.svg";
import { bestOfBreed } from "../params";
export const BestOfBreed = () => {
  return (
    <section className="container mx-auto">
      <h1 className="text-page-title my-8 md:my-16">{LangInput("bestOfBreed")}</h1>
      <img className="md:hidden p-8 pb-16 mx-auto" src={trophy} alt={LangInput("bestOfBreed")} />
      <div className="md:flex justify-around items-center">
        <ul className="md:w-1/2 md:mr-4 list-standard">
          {bestOfBreed && bestOfBreed.map(el => 
            (<li key={el.title}>{LangInput(el.title)}</li>)
          )}          
        </ul>
        <img className="hidden md:block md:w-1/2 md:ml-4 md:p-24" src={trophy} alt={LangInput("bestOfBreed")} />
      </div>
      <img className="mx-auto mt-24" src={kvTrophy} alt={LangInput("bestOfBreed")} />
    </section>
  );
};
