/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import English from "./english.json";
import Chinese from "./chinese.json";
import { Tlang, TlangContext, TReactNode } from "./types";

export const LangContext = React.createContext<TlangContext>({
  en: English,
  tc: Chinese,
  langChange: "tc",
  changeLange: (key: string) => console.log("Success!!!!", key)
});

export const LangContextProvider = ({ children }: TReactNode) => {
  const [langChange, setLangChange] = React.useState<string>(
    localStorage.getItem("SelectLanguage") || ""
  );
  const [en] = React.useState<Tlang>(English);
  const [tc] = React.useState<Tlang>(Chinese);

  const changeLange = (param: string) => {
    setLangChange(param);
  };

  React.useEffect(() => {
    localStorage.setItem("SelectLanguage", langChange);
  }, [langChange, setLangChange]);

  return (
    <LangContext.Provider value={{ en, tc, langChange, changeLange }}>
      {children}
    </LangContext.Provider>
  );
};

export const LangInput = (key: string) => {
  const contextValue = React.useContext(LangContext);

  return contextValue.langChange === "en"
    ? contextValue.en[key]
    : contextValue.tc[key];
};
