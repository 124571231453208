/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { LangInput } from "../LangProvider";
import kvPregnant from "../assets/support-life-stages/kv-pregnant.png";
import kvBaby from "../assets/support-life-stages/kv-baby.png";
import kvPrimary from "../assets/support-life-stages/kv-primary.png";
import kvSecondary from "../assets/support-life-stages/kv-secondary.png";
import kvParent from "../assets/support-life-stages/kv-family.png";
import kvFamily from "../assets/support-life-stages/kv-family-2.png";

import { knowledgeableParents } from "../params";

export const FormativeStageCard = ({
  img,
  title
}: {
  img: string;
  title: string;
}) => (
  <article className="flex-1 flex flex-col justify-between bg-primary-lightBg px-2 pb-4 pt-8 rounded-lg m-2 text-center h-82">
    <h2 className="text-sub-title">{LangInput(title)}</h2>
    <img
      className="object-none object-bottom"
      src={img}
      alt={LangInput(title)}
    />
  </article>
);

export const SupportingLife = () => {
  return (
    <section className="container mx-auto">
      <h1 className="text-page-title my-16">{LangInput("supportingLife")}</h1>
      <section className="md:flex mb-16 md:mb-24">
        <FormativeStageCard
          img={kvPregnant}
          title="prenatal"></FormativeStageCard>
        <FormativeStageCard img={kvBaby} title="baby"></FormativeStageCard>
        <FormativeStageCard
          img={kvPrimary}
          title="primary"></FormativeStageCard>
        <FormativeStageCard
          img={kvSecondary}
          title="secondary"></FormativeStageCard>
        <FormativeStageCard img={kvParent} title="parent"></FormativeStageCard>
      </section>
      <section className="md:flex mb-24 justify-between items-center">
        <img
          className="m-24 my-0 hidden md:block"
          src={kvFamily}
          alt="kvFamily"
        />

        <article className="flex-auto">
          <h2 className="text-sub-title mb-8">
            {LangInput("KnowledgeableParents")}
          </h2>
          <img
            className="p-8 my-0 mx-auto md:hidden"
            src={kvFamily}
            alt="kvFamily"
          />
          <p className="text-content mb-4">
            {knowledgeableParents &&
              knowledgeableParents
                .filter((el, idx) => idx <= 3)
                .map(el => <span key={el.title}>{LangInput(el.title)} </span>)}
          </p>
          <ul className="list-standard">
            {knowledgeableParents &&
              knowledgeableParents
                .filter((el, idx) => idx > 3)
                .map(el => <li key={el.title}>{LangInput(el.title)}</li>)}
          </ul>
        </article>
      </section>
    </section>
  );
};
