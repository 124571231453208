/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { LangInput } from "../LangProvider";
import kvContact from "../assets/contact/kv-contact.png";
import iconEmail from "../assets/contact/email.svg";
import iconLocation from "../assets/contact/location.svg";
import iconPhone from "../assets/contact/phone.svg";
import iconSmartphone from "../assets/contact/smartphone.svg";

export type TContactItem = {
  icon: string;
  label: string;
  value: string;
};

export const ContactItem = ({ icon, label, value }: TContactItem) => (
  <div className="md:flex flex-row mb-4">
    <img className="mr-4 hidden md:block" src={icon} alt={label} />
    <div className="w-2/6 md:w-1/6 text-label">{label}</div>
    <div className="text-label-value">{value}</div>
  </div>
);

export const ContactUsSection = () => (
  <section className="container mx-auto pt-16 md:flex flex-row justify-between overflow-hidden">
    <div className="md:w-4/6">
      <div className="text-title mb-12 text-gray-900">
        {LangInput("contact")}
      </div>
      <div className="mb-16 md:mb-0">
        <ContactItem
          icon={iconPhone}
          label={LangInput("hotlineLabel")}
          value={LangInput("hotline")}></ContactItem>
        <ContactItem
          icon={iconSmartphone}
          label={LangInput("whatsappLabel")}
          value={LangInput("whatsapp")}></ContactItem>
        <ContactItem
          icon={iconEmail}
          label={LangInput("emailLabel")}
          value={LangInput("email")}></ContactItem>
        <ContactItem
          icon={iconLocation}
          label={LangInput("addressLabel")}
          value={LangInput("address")}></ContactItem>
      </div>
    </div>
    <div className="md:w-2/6 md:pt-12 md:-mb-2">
      <img src={kvContact} alt={LangInput("contact")} className="mx-auto" />
    </div>
  </section>
);
