/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { LangInput } from "../LangProvider";
import { csnnet, WhatWeDo } from "../params";
import { ReactComponent as Kv } from "../assets/what-we-do/kv-csn-net.svg";
import { ReactComponent as Bg } from "../assets/what-we-do/bg-1.svg";
import { ReactComponent as Icon } from "../assets/what-we-do/icon-csn-net.svg";
import { NextPrevNav } from "../components/Gear";

export const CSNnet = () => {
  return (
    <section className="relative">

      {/* Summary */}
      <section className="container mx-auto md:flex justify-center items-center md:my-16">
        <div className="my-8 md:my-0">
          <Icon className="mx-auto md:m-0 mb-4" />
          <h1 className="text-page-title text-tertiary normal-case">{LangInput("csnNet")}</h1>
        </div>
        <ul className="list-standard md:ml-16">
          {csnnet && csnnet.map(el => (
            <li key={el.title}>{LangInput(el.title)}</li>
          ))}
        </ul>
      </section>

      {/* BG visuals */}
      <section className="relative overflow-hidden">
        <Bg className="invisible" />
        <Bg className="absolute top-0 left-0 right-0 mx-auto" />
        <Kv className="absolute top-0 left-0 right-0 mx-auto" style={{ width: '80%' }} />
      </section>

      {/* Next / Prev */}
      <NextPrevNav next={WhatWeDo.items[1].url} prev={WhatWeDo.items[3].url}></NextPrevNav>      
    </section>
  );
};
