/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { BgColorButton, ButtonList } from "../components";
import { LangInput } from "../LangProvider";
import ESG from "../assets/esg.png";

export const ESGPrinciple = () => {
  return (
    <div className="m-4">
      <span className="flex md:flex-row flex-col justify-center items-center mx-auto">
        <img src={ESG} alt="ESG" className="w-108" />
      </span>
      <p
        className={`text-xl text-white bg-red-500 m-2 px-4 py-2 rounded-full ${
          LangInput("menu") === "Menu" ? "w-36" : "w-26"
        }`}>
        {LangInput("esg")}
      </p>
      <p className="text-lg m-4">{LangInput("NotJust")}</p>
      <div className="flex lg:flex-row flex-col justify-center item-center">
        <div className="flex flex-col m-12">
          <BgColorButton
            title="Environmental"
            bgColor="bg-green-500"
            color="text-white"
            textSize="text-3xl"
          />
          <ButtonList items={["SavePaper", "ReduceTravel", "CutsPollution"]} />
        </div>
        <div className="flex flex-col m-12">
          <BgColorButton
            title="Social"
            bgColor="bg-green-500"
            color="text-white"
            textSize="text-3xl"
          />
          <ButtonList
            items={[
              "PhilanthropicProjects",
              "CorporateSocial",
              "ImproveCommunication",
              "InclusionDiversity",
              "SupportSocialWelfare"
            ]}
          />
        </div>
        <div className="flex flex-col m-12">
          <BgColorButton
            title="Governance"
            bgColor="bg-green-500"
            color="text-white"
            textSize="text-3xl"
          />
          <ButtonList
            items={[
              "EnhanceCooperation",
              "ImproveRecordKeeping",
              "MonitorCompliance",
              "ReduceStaffTurnover",
              "ImproveStaff"
            ]}
          />
        </div>
      </div>
    </div>
  );
};
